<template>
  <v-app>
    <header>
      <v-container fluid style="background-color: #151f42" class="py-0">
        <v-container class="py-0">
          <v-app-bar color="transparent" dark dense flat>
            <v-toolbar-title class="text-h6"
              >МОНГОЛ УЛСЫН ЭРДМИЙН ИX СУРГУУЛЬ</v-toolbar-title
            >
          </v-app-bar>
        </v-container>
      </v-container>
      <v-container fluid class="py-10" style="background-color: #ffffff">
        <v-container>
          <v-toolbar flat dense>
            <v-toolbar-title class="text-h3" style="font-weight: 400">
              <a
                href=""
                style="
                  border-right: 1px solid black;
                  text-decoration: none;
                  font-weight: 400;
                  color: #b1040e;
                "
                class="pr-2 text-h4 font-weight-bold"
                >МОНГОЛ УЛСЫН ЭРДМИЙН ИX СУРГУУЛЬ</a
              >

              Event Calendar
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-text-field
              rounded
              solo
              outlined
              dense
              style="height: 100%; width: 1%"
              append-icon="mdi-magnify"
              label="Мэдээлэл xайx"
              class="gone ungone"
            >
            </v-text-field>
          </v-toolbar>
        </v-container>
      </v-container>
    </header>

    <v-navigation-drawer absolute temporary v-model="drawer" dark>
      <v-list shaped>
        <v-subheader class="text-h4">MENU</v-subheader>
        <v-list-item-group v-model="selectedItem" color="red">
          <v-list-item v-for="(item, i) in items1" :key="i">
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader class="text-h4">Others</v-subheader>
          <v-list-item v-for="(item, i) in items" :key="i">
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <section>
        <v-container fluid style="background-color: #213F9A">
          <v-container>
            <v-row justify="end">
              <v-col cols="12" md="7" lg="8" sm="12">
                <span style="color: white" class="text-h5"
                  >МАГАДЛАН ИТГЭМЖЛЭЛ</span
                >
                <h1 style="color: white; font-weight: bold" class="text-h3">
                  БМИҮЗ-ийн шинжээчийн баг ажиллана
                </h1>
                <p
                  style="color: white; font-weight: 300"
                  class="text-subtitle-1 pt-2"
                >
                  Боловсролын магадлан итгэмжлэх үндэсний зөвлөл
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
        <v-container fluid color="white">
          <v-container>
            <v-row>
              <v-col cols="12" md="6" lg="4" sm="12" class="abs abs1 abs2 abs3">
                <v-card>
                  <v-img src="/medee3.png"></v-img>
                  <v-card-text class="py-0">
                    <v-timeline align-top dense style="color: black">
                      <v-timeline-item
                        icon="mdi-calendar-blank"
                        color="white"
                        icon-color="#8c1515"
                      >
                        <v-row class="pt-1">
                          <v-col cols="12" class="pb-0">
                            <strong style="font-weight: bold"
                              >2021 оны 3 дугаар сарын 16-ны өдөр </strong
                            >
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <strong>8:00 am – 6:00 pm</strong>
                          </v-col>
                          <v-col cols="12" class="pt-0">
                            <a href="">Add to my Calendar</a>
                          </v-col>
                        </v-row>
                      </v-timeline-item>

                      <v-timeline-item
                        color="white"
                        icon="mdi-map-marker"
                        icon-color="#e98300"
                      >
                        <v-row class="pt-1">
                          <v-col cols="12">
                            <strong style="font-weight: bold"
                              >Erdem Tower</strong
                            >
                          </v-col>
                        </v-row>
                      </v-timeline-item>

                      <v-timeline-item
                        color="white"
                        icon="mdi-email"
                        icon-color="#009b76"
                      >
                        <v-row class="pt-1">
                          <v-col>
                            <a href="">Email Sponser</a>
                          </v-col>
                        </v-row>
                      </v-timeline-item>

                      <v-timeline-item
                        color="white"
                        icon-color="#007c92"
                        icon="mdi-account-group"
                      >
                        <v-row class="pt-1">
                          <v-col cols="12" class="pb-0">
                            <strong style="font-weight: bold"
                              >The event is open to:</strong
                            >
                          </v-col>
                          <v-col cols="12" class="pt-0">
                            <strong>Only invited</strong>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                      <v-timeline-item
                        color="white"
                        icon-color="#006cb8"
                        icon="mdi-ticket-account"
                      >
                        <v-row class="pt-1">
                          <v-col cols="12">
                            <strong>Free</strong>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" lg="6" sm="12">
                <h1 style="font-weight: bold" class="text-h3 mt-16">
                  Мэдээлэл:
                </h1>
                <p style="font-weight: 300" class="text-subtitle-1">
                  БМИҮЗ-ийн шинжээчийн баг ажиллана.
                </p>
                <p
                  style="font-weight: 400; line-height: 150%"
                  class="text-subtitle-1"
                >
                  Монгол улсын Эрдмийн их сургууль "Урлаг судлал" –ын магистр,
                  докторын хөтөлбөр хэрэгжүүлэхээр хүсэлт гаргасны дагуу
                  БМИҮЗ-ийн шинжээчийн баг тус сургууль дээр 2021 оны 3 дугаар
                  сарын 16-ны өдөр ажиллана. Энэ ажлын хүрээнд шинжээчид
                  Хөтөлбөрийн өөрийн үнэлгээний багийн боловсруулсан тайлан,
                  нотлох баримтуудтай танилцан багш нарын төлөөлөлтэй уулзах юм.
                   
                </p>

                <p
                  style="font-weight: 400; line-height: 150%"
                  class="text-subtitle-1"
                >
                  БМИҮЗ-ийн шинжээчийн баг  
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </section>
    </v-main>

    <v-footer dark class="" style="background: #151f42">
      <v-row justify="center" no-gutters>
        <v-col cols="12" class="pa-0 pt-4">
          <h1 class="text-center text-h3" style="font-weight: bold">
            Xолбоо бариx
          </h1>
        </v-col>
        <v-col cols="12" class="pa-0">
          <p class="text-center text-caption mt-4">
            Монгол улс, УБ хот, СБД 8-р хороо, Бага тойруу,
            <br />
            Сүхбаатарын талбай 20/6, Эрдмийн ордон
          </p>
        </v-col>
        <v-col>
          <v-row justify="center" class="py-4">
            <v-btn icon x-large color="primary">
              <v-icon>mdi-twitter</v-icon>
              <!-- http://gradedu.mn/# -->
            </v-btn>
            <v-btn icon x-large color="primary" target="blank">
              <a
                style="text-decoration: none"
                href="https://www.facebook.com/graduateuniversity"
                target="blank"
                ><v-icon>mdi-facebook</v-icon></a
              >
            </v-btn>
            <v-btn icon x-large color="primary">
              <a
                style="text-decoration: none"
                href="http://gradedu.mn/#"
                target="blank"
                ><v-icon>mdi-instagram</v-icon></a
              >
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="12" class="pa-0">
          <h1 class="text-center text-h5" style="font-weight: normal">
            Phone: (+976) 7600-2010, 7600-3010
          </h1>
        </v-col>
        <v-col cols="12" class="pa-1">
          <p class="text-center text-h6">Email: info@gradedu.mn</p>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>
<style>
.title {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.center {
  position: relative;
}

.shadow {
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, transparent);
}
body {
  line-height: 1.5;
  font-family: Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.8em;
  color: #000;
}
@media only screen and (min-width: 700px) {
  .ungone {
    visibility: visible;
  }
}
@media only screen and (max-width: 700px) {
  .gone {
    visibility: hidden;
  }
}
html {
  font-size: 13px;
}
@media screen and (min-width: 320px) {
  html {
    font-size: calc(13px + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  html {
    font-size: 19px;
  }
}
.bg {
  background: rgb(47, 36, 36);
  background: linear-gradient(
    180deg,
    rgba(47, 36, 36, 1) 27%,
    rgba(244, 244, 244, 1) 27%
  );
}
ul li::marker {
  font-size: 100%;
  color: black;
}
@media only screen and (max-width: 2000px) {
  .abs1 {
    margin-top: -10%;
  }
}

@media only screen and (max-width: 1903px) {
  .abs1 {
    margin-top: -18%;
  }
}
@media only screen and (max-width: 1263px) {
  .abs2 {
    margin-top: -32%;
  }
}
@media only screen and (max-width: 959px) {
  .abs3 {
    margin-top: 0%;
  }
}
</style>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
    items: ["Home", "Pricing", "About Us"],
    items1: [
      "News",
      "Events",
      "Academics",
      "Research",
      "Health Care",
      "Campus Life",
      "Admission",
      "About",
    ],
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>